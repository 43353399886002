import Vue from 'vue';
import AppTabs from './AppTabs';
import AppPage from './AppPage';
import AppList from './AppList';
import AppStatsMenu from './AppStatsMenu';
import AppMenuView from './AppMenuView';
import AppMenuDialog from './AppMenuDialog';
import AppMenuDialogs from './AppMenuDialogs';
import AppNumberDialog from './AppNumberDialog';
import AppToolbar from './AppToolbar';
import AppForm from './AppForm';
import AppFormDialog from './AppFormDialog';
import AppFormSetting from './AppFormSetting';
import AppListDialog from './AppListDialog';
import AppResultDialog from './AppResultDialog';

Vue.component(AppTabs.name, AppTabs);
Vue.component(AppPage.name, AppPage);
Vue.component(AppList.name, AppList);
Vue.component(AppStatsMenu.name, AppStatsMenu);
Vue.component(AppMenuView.name, AppMenuView);
Vue.component(AppMenuDialog.name, AppMenuDialog);
Vue.component(AppMenuDialogs.name, AppMenuDialogs);
Vue.component(AppNumberDialog.name, AppNumberDialog);
Vue.component(AppToolbar.name, AppToolbar);
Vue.component(AppForm.name, AppForm);
Vue.component(AppFormDialog.name, AppFormDialog);
Vue.component(AppFormSetting.name, AppFormSetting);
Vue.component(AppListDialog.name, AppListDialog);
Vue.component(AppResultDialog.name, AppResultDialog);
