// 食堂管理

export default [
  {
    path: '/canteen/account',
    component: resolve =>
      require(['../components/page/canteen/account'], resolve),
    meta: { title: '人员管理' }
  },
  {
    path: '/canteen/accountBalance',
    component: resolve =>
      require(['../components/page/canteen/accountBalance'], resolve),
    meta: { title: '人员余额查询' }
  },
  {
    path: '/canteen/recharge',
    component: resolve =>
      require(['../components/page/canteen/recharge'], resolve),
    meta: { title: '充值管理' }
  },
  {
    path: '/canteen/deduction',
    component: resolve =>
      require(['../components/page/canteen/deduction'], resolve),
    meta: { title: '扣费管理' }
  },
  {
    path: '/canteen/consumeStatistics',
    component: resolve =>
      require(['../components/page/canteen/consumeStatistics'], resolve),
    meta: { title: '消费统计' }
  },
  {
    path: '/canteen/clear',
    component: resolve =>
      require(['../components/page/canteen/clear'], resolve),
    meta: { title: '清零记录' }
  },
  {
    path: '/canteen/menu',
    component: resolve => require(['../components/page/canteen/menu'], resolve),
    meta: { title: '每周菜单' }
  },
  {
    path: '/canteen/singlePoint',
    component: resolve => require(['../components/page/canteen/singlePoint'], resolve),
    meta: { title: '单点菜单' }
  },
  {
    path: '/canteen/menuStatistics',
    component: resolve =>
      require(['../components/page/canteen/menuStatistics'], resolve),
    meta: { title: '菜品统计' }
  },
  {
    path: '/canteen/appointment',
    component: resolve =>
      require(['../components/page/canteen/appointment'], resolve),
    meta: { title: '订餐预约' }
  },
  {
    path: '/canteen/temp',
    component: resolve => require(['../components/page/canteen/temp'], resolve),
    meta: { title: '临时预约' }
  },
  {
    path: '/canteen/business',
    component: resolve =>
      require(['../components/page/canteen/business'], resolve),
    meta: { title: '业务订餐' }
  },
  {
    path: '/canteen/takeaway',
    component: resolve =>
      require(['../components/page/canteen/takeaway'], resolve),
    meta: { title: '外卖预约' }
  },
  {
    path: '/canteen/entertain',
    component: resolve =>
      require(['../components/page/canteen/entertain'], resolve),
    meta: { title: '招待预约' }
  },
  {
    path: '/canteen/group',
    component: resolve =>
      require(['../components/page/canteen/group'], resolve),
    meta: { title: '分组管理' }
  },
  {
    path: '/canteen/setting',
    component: resolve =>
      require(['../components/page/canteen/setting'], resolve),
    meta: { title: '参数配置' }
  }
];
