<template>
  <el-dialog
    v-bind="$attrs"
    append-to-body
    :title="title"
    v-on="$listeners"
    @open="onOpen"
  >
    <div v-loading="loading" class="menu-view">
      <div style="display: flex;justify-content: space-around;">
        <el-input
            ref="input"
            v-model.trim="name"
            class="search"
            suffix-icon="el-icon-search"
            clearable
            placeholder="可输入要搜索菜单名称"
            @input="stabilization"
        />
        <el-button @click="name='';onOpen()">刷新</el-button>
        <el-button @click="addMenu">新增商品</el-button>
      </div>
      <el-divider />
      <app-menu-view
        v-bind="$attrs"
        :items="items"
        disable-remove
        view-height="460px"
        @select="onSelect"
      />
    </div>
  </el-dialog>
</template>

<script>
import request from '@/utils/request';

export default {
  name: 'AppMenuDialogs',
  inheritAttrs: false,
  props: { type: Number, category: [Number, Array] },
  data() {
    return {
      loading: false,
      name: undefined,
      totalNum: 50,
      items: [],
      tiemr:null,
    };
  },
  computed: {
    typeName() {
      console.log('this.type', this.type)
      const types = ['', '早餐', '午餐', '晚餐'];
      return types[this.type] ?? '';
    },
    categoryName() {
      console.log('this.category',this.category)
      if (!this.category) {
        return '外卖';
      }
      if (Array.isArray(this.category)) {
        return '粉、面、饭';
      }
      const breakfast = [
        '',
        '粉',
        '面',
        '粥',
        '面点、蛋',
        '饮品',
        '套餐',
        '饭'
      ];
      const lunchAndDinner = [
        '',
        '主荤',
        '副荤',
        '素菜',
        '例汤',
        '营养',
        '水饺'
      ];
      const categories = {
        1: breakfast,
        2: lunchAndDinner,
        3: lunchAndDinner,
        4: ['', '早上', '中午领取', '晚上领取'],
        5: ['', '早餐', '中餐', '晚餐']
      };
      return categories[this.type][this.category];
    },
    title() {
      const { categoryName } = this;
      return `请选择《${categoryName}》`;
    }
  },
  methods: {
    // 使用类型、分类、名称参数加载菜单库
    async onOpen() {
      const { type, category, name, $refs } = this;
      try {
        this.loading = true;
        const params = { type:5, category, name, pageSize: 500, isSingle: 1 };
        const { data } = await request.get('/canteenCookbook', { params });
        this.items = data.data;
        $refs.input.focus();
      } finally {
        this.loading = false;
      }
    },
    // 搜索框防抖
    stabilization() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(this.onOpen, 500)
    },
    // 选中的菜单
    onSelect(e) {
      const { totalNum } = this;
      this.$emit('select', { ...e, totalNum });
      if (this.type != 4) {
        this.$emit('update:visible', false);
      }
    },
    addMenu(){
      this.$parent.$parent.dialog = this.$parent.$parent.creator
      this.$parent.$parent.visible = true
    },
  }
};
</script>

<style lang="scss" scoped>
.menu-view {
  display: flex;
  flex-direction: column;

  .search {
    //margin: auto;
    width: 360px;
  }
}
</style>
